<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="user-qrcode">
      <div class="user-qrcode-top">
        <p class="user-qrcode-top-title">下载二维码</p>
        <button
          class="user-qrcode-top-close"
          :style="{
            backgroundImage: `url(${require('@imgs/close-card.png')})`,
          }"
          @click="onCloseClick"
        ></button>
      </div>
      <div class="user-qrcode-middle">
        <div class="user-qrcode-middle-code">
          <vue-qr
            :text="qrcodeContent"
            :size="200"
            :logoSrc="imageUrl"
            ref="qrCode"
          ></vue-qr>
        </div>
      </div>
      <div class="user-qrcode-bottom">
        <span class="user-qrcode-bottom-download" @click="onDownloadClick">
          下载二维码
        </span>
      </div>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view.vue";
import VueQr from "vue-qr";

export default {
  name: "UserQrcode",
  components: {
    PopView,
    VueQr,
  },

  props: {
    hospitalID: {
      type: Number,
      required: true,
    },
    hospitalName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      qrcodeUrl: "",
      qrcodeInstance: null,
      imageUrl: require("@imgs/logo-bajie-small-28.png"), //icon路径
    };
  },

  computed: {
    qrcodeContent() {
      console.log(
        process.env.VUE_APP_API_ADD_USER +
          `?createByHospitalId=${this.hospitalID}&hospitalName=${this.hospitalName}&type=${1}`
      );
      return (
        process.env.VUE_APP_API_ADD_USER +
        `?createByHospitalId=${this.hospitalID}&hospitalName=${this.hospitalName}&type=${1}`
      );
    },
  },

  mounted() {},

  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onDownloadClick() {
      try {
        const iconUrl = this.$refs.qrCode.$el.src;
        const a = document.createElement("a");
        const event = new MouseEvent("click");
        a.download = "新建用户二维码.png";
        a.href = iconUrl;
        a.dispatchEvent(event);
      } catch (error) {
        console.error("下载二维码出错:", error);
        this.$toast.showRed("下载失败，请稍后重试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-qrcode {
  width: rem(400);

  &-top {
    width: 100%;
    height: rem(55);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    padding: rem(0) rem(24);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(16);
    }

    &-close {
      width: rem(16);
      height: rem(16);
      background-size: rem(16) rem(16);
      background-repeat: no-repeat;
    }
  }

  &-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(24) rem(0) 0px;

    &-code {
      width: rem(200);
      height: rem(200);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: rem(16);

      /* 二维码样式美化 */
      /deep/ canvas {
        border-radius: rem(8);
        box-shadow: 0 rem(2) rem(8) rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-bottom {
    width: 100%;
    height: rem(56);
    box-sizing: border-box;
    padding: rem(0) rem(24) rem(16);
    display: flex;
    justify-content: center;

    &-download {
      font-size: rem(14);
      color: #325fac;
      cursor: pointer;
    }
  }
}
</style>
