<template>
  <div class="user-manage">
    <div class="user-manage-content">
      <div class="user-manage-content-top">
        <div class="user-manage-content-top-left">
          <p class="user-manage-content-top-left-title">用户</p>
          <button
            class="user-manage-content-top-left-add-user"
            @click="onAddClick"
          >
            新增用户
          </button>
        </div>
        <keyword-search
          title="姓名或手机号"
          @onKeywordSearch="onKeywordSearch"
        ></keyword-search>
      </div>
      <page-list-container
        class="user-manage-content-bottom-list"
        :total="total"
        :pageSize="pageSize"
        :inputCurrentPage="currentPage"
        @onPageChange="onPageChange"
        @onSizeChange="onSizeChange"
      >
        <item-bar />
        <cell
          class="user-manage-content-bottom-list-item"
          v-for="(item, index) of userArray"
          :key="index"
          :model="item"
          :isSelected="selectedID === item.id"
          :canPreview="item.isReport == 1"
          @onSelectClick="onCellSelectClick"
          @onDeleteClick="onCellDeleteClick"
          @onPreviewClick="onCellPreviewClick"
          @onEditClick="onCellEditClick"
          @onDetailClick="onCellDetailClick"
        ></cell>
      </page-list-container>
    </div>

    <!-- 使用新的统一用户弹窗组件 -->
    <user-dialog
      v-if="isShowUserDialog"
      :isEdit="!!selectedModel"
      :model="selectedModel"
      @onCloseClick="onUserDialogCloseClick"
      @onConfirmClick="onUserDialogConfirmClick"
      @onQrCodeClick="onQrCodeClick"
    ></user-dialog>

    <!-- 用户详情组件 -->
    <user-detail
      v-if="isShowUserDetail"
      :userId="selectedID"
      :hospitalId="selectedHospitalID"
      @onCloseClick="onUserDetailCloseClick"
    ></user-detail>

    <!-- 用户二维码组件 -->
    <user-qrcode
      v-if="isShowUserQrcode"
      :hospitalID="hospitalID"
      :hospitalName="hospitalName"
      @onCloseClick="onUserQrcodeCloseClick"
    ></user-qrcode>

    <report-panel
      v-if="isShowReportPanel"
      :dataArray="reportArray"
      @onCloseClick="onReportPanelCloseClick"
      @onPreviewClick="onReportPanelPreviewClick"
    ></report-panel>
  </div>
</template>

<script>
import KeywordSearch from "@c/common/keyword-search.vue";
import ItemBar from "./item-bar.vue";
import Cell from "./cell.vue";
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import UserDialog from "./user-dialog.vue";
import UserDetail from "./user-detail.vue";
import UserQrcode from "./user-qrcode.vue";
import ReportPanel from "./report-panel/report-panel.vue";
import { ReportPreviewTool } from "@js/report-preview-tool.js";

export default {
  components: {
    KeywordSearch,
    ItemBar,
    Cell,
    PageListContainer,
    UserDialog,
    UserDetail,
    UserQrcode,
    ReportPanel,
  },

  data: function () {
    return {
      //userList
      userArray: [],
      total: undefined, // 显示总条数
      pageSize: undefined, // 每页显示条数
      currentPage: 1, // 当前的页数
      //keywords
      keywords: "",
      //cell
      selectedModel: undefined,
      //user dialog (add/edit)
      isShowUserDialog: false,
      //user detail
      isShowUserDetail: false,
      //user qrcode
      isShowUserQrcode: false,
      //report-panel
      isShowReportPanel: false,
      reportArray: [],
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },
    hospitalName: function () {
      return this.$store.state.selectedHospital.name;
    },

    selectedID: function () {
      return this.selectedModel == undefined
        ? undefined
        : this.selectedModel.id;
    },
    selectedHospitalID() {
      return this.selectedModel == undefined
        ? undefined
        : this.selectedModel.hospitalId;
    },
  },

  watch: {
    hospitalID: function () {
      this.currentPage = 1;
      this.userListRequest();
    },
  },

  mounted() {
    this.pageSize = bestPageSizeComputer();
    this.userListRequest();
  },

  methods: {
    onAddClick: function () {
      if (this.hospitalID === null) {
        this.$toast.showRed(MSG.pickHospitalRemind);
        return;
      }
      // 打开新增用户弹窗
      this.selectedModel = null;
      this.isShowUserDialog = true;
    },

    onKeywordSearch: function (value) {
      this.currentPage = 1;
      this.keywords = value;
      this.userListRequest();
    },

    //cell
    onCellSelectClick: function (model) {
      this.selectedModel = model;
    },

    onCellEditClick: function () {
      // 打开编辑用户弹窗
      this.isShowUserDialog = true;
    },

    onCellDeleteClick: function () {
      this.$alert.show("用户操作", "是否要删除用户？", () => {
        this.userDeleteRequest();
      });
    },

    onCellDetailClick: function () {
      // 打开用户详情弹窗
      this.isShowUserDetail = true;
    },

    async onCellPreviewClick() {
      await this.reportListRequest(this.selectedModel.id);
      if (
        this.reportArray.length === 1 &&
        this.reportArray[0].complete === "完整"
      ) {
        const firstReport = this.reportArray[0];
        ReportPreviewTool.previewReport(
          this.selectedModel.truename,
          firstReport.reportNumber,
          firstReport.createDat
        );
      } else {
        this.isShowReportPanel = true;
      }
    },

    //分页
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.userListRequest();
    },

    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.userListRequest();
    },

    // UserDialog 组件回调
    onUserDialogCloseClick: function () {
      this.isShowUserDialog = false;
    },

    onUserDialogConfirmClick: function (info) {
      if (info.id) {
        // 编辑模式
        this.userEditRequest(info);
      } else {
        // 新增模式
        this.userCreateRequest(info);
      }
      this.isShowUserDialog = false;
    },

    onQrCodeClick() {
      this.isShowUserQrcode = true;
      this.isShowUserDialog = false;
    },

    // UserDetail 组件回调
    onUserDetailCloseClick: function () {
      this.isShowUserDetail = false;
    },

    onUserDetailEditClick: function (userDetail) {
      this.isShowUserDetail = false;
      this.selectedModel = userDetail;
      this.isShowUserDialog = true;
    },

    onUserDetailQrcodeClick: function (userDetail) {
      this.isShowUserDetail = false;
      this.isShowUserQrcode = true;
    },

    onUserDetailReportsClick: function (userDetail) {
      this.isShowUserDetail = false;
      this.onCellPreviewClick();
    },

    // UserQrcode 组件回调
    onUserQrcodeCloseClick: function () {
      this.isShowUserQrcode = false;
    },

    //report-panel
    onReportPanelCloseClick: function () {
      this.isShowReportPanel = false;
    },

    onReportPanelPreviewClick: function (selectedReportModel) {
      ReportPreviewTool.previewReport(
        this.selectedModel.truename,
        selectedReportModel.reportNumber,
        selectedReportModel.createDat
      );
    },

    //request
    async userListRequest() {
      try {
        const params = {
          keyWord: this.keywords,
          pageNum: this.currentPage,
          hospitalId: this.hospitalID,
          pageSize: this.pageSize,
        };
        const response = await this.$api.getUserPageList(params);
        this.total = response.total;
        this.userArray = response.list;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async reportListRequest(uid) {
      try {
        const params = {
          hospitalId: this.hospitalID,
          uid: uid,
        };
        const response = await this.$api.getSleepReportList(params);
        this.reportArray = response;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async userDeleteRequest() {
      try {
        const params = {
          id: this.selectedModel.id,
        };
        await this.$api.deleteUser(params);
        this.$toast.showGreen(MSG.userDeleteSucceed);
        this.userListRequest();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async userCreateRequest(info) {
      try {
        const params = {
          trueName: info.name,
          birthday: info.birthday,
          height: info.height,
          weight: info.weight,
          sex: info.isFemale ? GENDER_CODE.女 : GENDER_CODE.男,
          mobile: info.phone,
          address: info.address,
          hospitalId: this.hospitalID,
          mzh: info.number,
          idCard: info.ID,
          bch: info.bch,
          department: info.department,
          frontPhoto: info.frontPhoto,
          leftPhoto: info.leftPhoto,
          rightPhoto: info.rightPhoto,
          remarks: info.remarks,
        };
        await this.$api.createUser(params);
        this.$toast.showGreen(MSG.userAddSucceed);
        this.currentPage = 1;
        this.keywords = "";
        this.userListRequest();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async userEditRequest(info) {
      try {
        const params = {
          id: this.selectedModel.id,
          trueName: info.name,
          birthday: info.birthday,
          height: info.height,
          weight: info.weight,
          sex: info.isFemale ? GENDER_CODE.女 : GENDER_CODE.男,
          mobile: info.phone,
          address: info.address,
          hospitalId: this.hospitalID,
          mzh: info.number,
          idCard: info.ID,
          bch: info.bch,
          department: info.department,
          frontPhoto: info.frontPhoto,
          leftPhoto: info.leftPhoto,
          rightPhoto: info.rightPhoto,
          remarks: info.remarks,
        };
        await this.$api.editUser(params);
        this.$toast.showGreen(MSG.userEditSucceed);
        this.currentPage = 1;
        this.keywords = "";
        this.userListRequest();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-manage {
  background-color: #eff2f7;
  width: 100%;

  &-content {
    background-color: white;
    border-radius: rem(2);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: rem(36);
    padding-right: rem(33);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-top {
      font-size: rem(16);
      width: 100%;
      height: rem(72);
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        display: flex;

        &-title {
          color: #325fac;
          font-size: rem(14);
          border-bottom: rem(2) solid #325fac;
          width: rem(100);
          height: rem(32);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-add-user {
          color: white;
          background-color: #325fac;
          font-size: rem(14);
          width: rem(88);
          height: rem(32);
          border-radius: 4px;
          margin-left: rem(74);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-bottom {
      width: 100%;
      height: 100%;
      margin-top: rem(26);
      display: flex;
      flex-direction: column;
      align-items: center;

      &-list {
        width: 100%;
      }

      &-empty-info {
        color: rgba($color: black, $alpha: 0.85);
        font-size: rem(14);
        width: 100%;
        margin-top: rem(100);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-pagination {
        width: 100%;
        height: rem(90);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
