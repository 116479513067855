<template>
  <div class="upload-photo">
    <div class="upload-photo-title">
      <span v-if="isMust" class="upload-photo-title-must">*</span>
      <span>{{ title }}</span>
    </div>
    <div class="upload-photo-container">
      <div class="upload-photo-container-left">
        <div class="upload-photo-container-left-content">
          <div
            class="upload-photo-container-left-content-image"
            v-if="photoUrl"
            @click.stop="onClickAdd"
          >
            <img
              class="upload-photo-container-left-content-image-photo"
              :src="photoUrl"
            />
            <img
              class="upload-photo-container-left-content-image-delete"
              src="@imgs/photo-delete.png"
              @click.stop="onClickDelete"
            />
          </div>
          <div
            v-if="!photoUrl"
            class="upload-photo-container-left-content-no-image"
            @click.stop="onClickAdd"
          >
            <img
              class="upload-photo-container-left-content-no-image-background"
              src="@imgs/photo-empty-background.webp"
            />
            <div class="upload-photo-container-left-content-no-image-content">
              <img
                class="upload-photo-container-left-content-no-image-content-camera"
                src="@imgs/photo-empty-camera.webp"
              />
              <p
                class="upload-photo-container-left-content-no-image-content-info"
              >
                上传照片
              </p>
            </div>
          </div>
        </div>
        <p class="upload-photo-container-left-tips">{{ tips }}</p>
      </div>
      <div class="upload-photo-container-right">
        <img
          class="upload-photo-container-right-photo"
          :src="examplePhoto"
          alt=""
        />
        <p class="upload-photo-container-right-title">拍照示范图</p>
      </div>
    </div>
  </div>
</template>

<script>
import frontExampleImg from "@imgs/example-front.webp";
import leftExampleImg from "@imgs/example-left.webp";
import rightExampleImg from "@imgs/example-right.webp";

export default {
  name: "UploadPhoto",
  props: {
    photoUrl: String,
    type: String,
    isMust: Boolean,
  },
  data() {
    return {
      frontExampleImg: frontExampleImg,
      leftExampleImg: leftExampleImg,
      rightExampleImg: rightExampleImg,
    };
  },

  computed: {
    title() {
      switch (this.type) {
        case "front":
          return "正面照";
        case "left":
          return "左面照";
        case "right":
          return "右面照";
        default:
          break;
      }
    },

    examplePhoto() {
      switch (this.type) {
        case "front":
          return this.frontExampleImg;
        case "left":
          return this.leftExampleImg;
        case "right":
          return this.rightExampleImg;
        default:
          break;
      }
    },

    tips() {
      switch (this.type) {
        case "front":
          return "正脸要求：\n确保患者面向相机，五官清晰可见。\n调整相机高度与患者眼睛平齐。\n确保面部在相框中央，头部不倾斜。";
        case "left":
          return "侧脸要求：\n确保左脸 下颌、眉毛和耳朵在相框内，\n保持自然放松。";
        case "right":
          return "侧脸要求：\n确保右脸 下颌、眉毛和耳朵在相框内，\n保持自然放松。";
        default:
          break;
      }
    },
  },

  created() {},
  mounted() {},

  methods: {
    onClickDelete() {
      this.$emit("onClickDelete");
    },
    onClickAdd() {
      this.$emit("onClickAdd");
    },
  },
};
</script>
<style scoped lang="scss">
@import "/src/assets/css/constants.scss";

.upload-photo {
  background-color: white;
  width: 100%;
  height: 193px;
  display: flex;
  flex-direction: column;

  &-title {
    font-size: 14px;
    color: #000;
    height: 40px;
    box-sizing: border-box;
    padding-left: resize(100);
    display: flex;
    align-items: center;

    &-must {
      color: #ff0000;
      margin-right: resize(4);
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 78px 0 resize(100);

    &-left {
      display: flex;
      flex-direction: column;

      &-content {
        // margin-top: resize(26);
        // margin-left: resize(94);
        width: resize(111);
        height: resize(106);
        border: 1px;
        position: relative;
        overflow: visible;

        &-image {
          position: absolute;
          overflow: visible;
          width: 100%;
          height: 100%;

          &-photo {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 6px;

            :deep(.van-image__img) {
              object-fit: cover;
            }
          }
          &-delete {
            position: absolute;
            width: 20px;
            height: 20px;
            top: -10px;
            right: -10px;
          }
        }

        &-no-image {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &-background {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          &-content {
            z-index: 1;
            position: absolute;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-camera {
              width: resize(38);
              height: resize(38);
            }

            &-info {
              color: #8f8f8f;
              font-size: resize(13);
              line-height: resize(13);
              margin-top: resize(8);
            }
          }
        }
      }

      &-tips {
        font-size: resize(15);
        line-height: resize(20);
        white-space: pre-wrap;
        margin-top: resize(31);
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-photo {
        width: resize(137);
        height: resize(183);
      }

      &-title {
        color: #8f8f8f;
        font-size: resize(15);
        line-height: resize(15);
        margin-top: resize(32);
      }
    }
  }
}
</style>
