<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="user-detail">
      <div class="user-detail-top">
        <p class="user-detail-top-title">用户详情</p>
        <button
          class="user-detail-top-close"
          :style="{
            backgroundImage: `url(${require('@imgs/close-card.png')})`,
          }"
          @click="onCloseClick"
        ></button>
      </div>
      <div class="user-detail-middle">
        <div class="user-detail-middle-content">
          <div
            v-for="(section, index) in fieldConfigs"
            :key="index"
            class="user-detail-middle-content-section"
            :style="{
              width: section.oneLine ? '100%' : '50%',
            }"
          >
            <div
              class="user-detail-middle-content-section-item"
              v-if="section.fieldCode != 'photos'"
            >
              <span class="user-detail-middle-content-section-item-label">
                {{ section.fieldName }}：
              </span>
              <span
                class="user-detail-middle-content-section-item-value"
                v-if="section.fieldCode === 'sex'"
              >
                {{
                  userDetail[codeMap[section.fieldCode]]
                    ? userDetail[codeMap[section.fieldCode]] === 1
                      ? "男"
                      : "女"
                    : "-"
                }}
              </span>
              <span
                class="user-detail-middle-content-section-item-value"
                v-else
              >
                {{ userDetail[codeMap[section.fieldCode]] || "-" }}
                <template v-if="section.fieldCode === 'height'">cm</template>
                <template v-if="section.fieldCode === 'weight'">kg</template>
              </span>
            </div>
            <template v-else>
              <div
                class="user-detail-middle-content-section-item"
                v-if="userDetail.frontPhoto"
              >
                <span class="user-detail-middle-content-section-item-label">
                  正面照
                </span>
                <span class="user-detail-middle-content-section-item-value">
                  <img
                    v-if="userDetail.frontPhoto"
                    class="user-detail-middle-content-section-item-value-img"
                    :src="userDetail.frontPhoto"
                    alt="正面照"
                  />
                  <div
                    v-else
                    class="user-detail-middle-content-section-item-value-img no-photo"
                  ></div>
                </span>
              </div>

              <div
                class="user-detail-middle-content-section-item"
                style="margin-top: 10px"
                v-if="userDetail.leftPhoto"
              >
                <span class="user-detail-middle-content-section-item-label">
                  左面照
                </span>
                <span class="user-detail-middle-content-section-item-value">
                  <img
                    v-if="userDetail.leftPhoto"
                    class="user-detail-middle-content-section-item-value-img"
                    :src="userDetail.leftPhoto"
                    alt="左侧面照"
                  />
                  <div
                    v-else
                    class="user-detail-middle-content-section-item-value-img no-photo"
                  ></div>
                </span>
              </div>

              <div
                class="user-detail-middle-content-section-item"
                style="margin-top: 10px"
                v-if="userDetail.rightPhoto"
              >
                <span class="user-detail-middle-content-section-item-label">
                  右面照
                </span>
                <span class="user-detail-middle-content-section-item-value">
                  <img
                    v-if="userDetail.rightPhoto"
                    class="user-detail-middle-content-section-item-value-img"
                    :src="userDetail.rightPhoto"
                    alt="正面照"
                  />
                  <div
                    v-else
                    class="user-detail-middle-content-section-item-value-img no-photo"
                  ></div>
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="user-detail-bottom">
        <button class="user-detail-bottom-cancel" @click="onCancelClick">
          取消
        </button>
        <button class="user-detail-bottom-confirm" @click="onConfirmClick">
          确定
        </button>
      </div>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view.vue";

export default {
  name: "UserDetail",
  components: {
    PopView,
  },

  props: {
    // 用户ID
    userId: {
      type: [Number, String],
      required: true,
    },
    hospitalId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      userDetail: {},
      reports: [],
      loading: true,
      fieldConfigs: [],

      codeMap: {
        name: "trueName", // 姓名
        birthday: "birthday", // 生日
        sex: "sex", // 性别
        phone: "mobile", // 手机号
        height: "height", // 身高
        weight: "weight", // 体重
        id_card: "idCard", // 身份证
        address: "address", // 地址
        medical_record: "mzh", // 病案号
        bed_number: "bch", // 床位号
        department: "department", // 科室
        photos: "photos", // 正面照
        remarks: "remarks", // 备注
      },
    };
  },

  computed: {
    userGender() {
      return this.userDetail.sex;
    },

    userAge() {
      if (!this.userDetail.age && !this.userDetail.birthday) return null;

      if (this.userDetail.age) return this.userDetail.age;

      // 如果有生日，计算年龄
      if (this.userDetail.birthday) {
        const birthDate = new Date(this.userDetail.birthday.replace(/-/g, "/"));
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }

        return age;
      }

      return null;
    },
  },

  created() {
    this.fetchUserDetail();
    this.fetchFieldConfigs();
  },

  methods: {
    async fetchUserDetail() {
      try {
        this.loading = true;
        const params = { id: this.userId };
        const response = await this.$api.getUserDetail(params);
        this.userDetail = response;
      } catch (error) {
        this.$toast.showRed(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchFieldConfigs() {
      try {
        const params = {
          hospitalId: this.hospitalId,
          platformType: 1,
        };
        const response = await this.$api.getUserFieldConfigList(params);
        // 按照fieldType分组字段
        const typeOneFields = [
          response.find((field) => field.fieldCode === "name"),
          response.find((field) => field.fieldCode === "sex"),
          response.find((field) => field.fieldCode === "birthday"),
          response.find((field) => field.fieldCode === "phone"),
        ];

        const typeTwoFields = response
          .filter((field) => field.fieldType === 2)
          .sort((a, b) => a.sortOrder - b.sortOrder);
        // 将所有字段合并到fieldList，但保持分组顺序
        this.fieldConfigs = [...typeOneFields, ...typeTwoFields].map((el) => {
          return {
            ...el,
            oneLine: ["remarks", "id_card", "photos"].some(
              (item) => item === el.fieldCode
            ), //是否独占一行
          };
        });
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onCancelClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      this.$emit("onCloseClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-detail {
  width: rem(500);
  max-height: rem(700);
  display: flex;
  flex-direction: column;

  &-top {
    width: 100%;
    min-height: rem(55);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    padding: rem(0) rem(24);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(16);
    }

    &-close {
      width: rem(16);
      height: rem(16);
      background-size: rem(16) rem(16);
      background-repeat: no-repeat;
    }
  }

  &-middle {
    flex: 1;
    overflow-y: auto;
    padding: rem(20) rem(24);
    width: calc(100% - rem(48));
    &-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      &-section {
        margin-bottom: rem(24);

        &-item {
          display: flex;
          width: 100%;
          font-size: rem(14);

          &-label {
            width: rem(80);
            color: #333;
          }

          &-value {
            flex: 1;
            color: #333;
            word-break: break-all;
            &-img {
              width: 96px;
              height: 96px;
              border-radius: 5px;
            }
            .no-photo {
              background-color: #f7f7f7;
              border: 1px dashed #999;
            }
          }
        }
      }
    }
  }

  &-bottom {
    width: 100%;
    min-height: rem(56);
    box-sizing: border-box;
    padding-top: rem(10);
    padding-right: rem(16);
    display: flex;
    justify-content: flex-end;
    border-top: rem(1) solid rgba($color: black, $alpha: 0.09);

    &-confirm {
      color: white;
      background-color: #325fac;
      font-size: rem(14);
      border-radius: rem(3);
      width: rem(65);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cancel {
      color: rgba($color: #000000, $alpha: 0.65);
      background-color: white;
      font-size: rem(14);
      border-radius: rem(3);
      border: rem(1) solid #e8e8e8;
      width: rem(65);
      height: rem(32);
      margin-right: rem(8);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
