<template>
  <div class="cell" :class="{ selected: isSelected }" @click="onSelectClick">
    <div class="cell-unit name">{{ model.trueName }}</div>
    <div class="cell-unit gender">{{ genderText }}</div>
    <div class="cell-unit age">{{ ageText }}</div>
    <div class="cell-unit phone-number">{{ model.mobile }}</div>
    <div class="cell-unit monitor-status">{{ monitorStatus }}</div>
    <div class="cell-unit organization">{{ model.hospitalName }}</div>
    <div class="cell-unit operation">
      <div class="cell-unit-content">
        <cell-unit
          color="#f45c50"
          :iconSRC="trashSRC"
          title="删除"
          @onClick="onDeleteClick"
        ></cell-unit>
        <div class="cell-unit-content-slash"></div>
        <cell-unit
          color="#325FAC"
          :iconSRC="detailSRC"
          title="详情"
          @onClick="onDetailClick"
        ></cell-unit>
        <div class="cell-unit-content-slash"></div>
        <cell-unit
          color="#325FAC"
          :iconSRC="editSRC"
          title="编辑"
          @onClick="onEditClick"
        ></cell-unit>
        <div class="cell-unit-content-slash"></div>
        <report-cell-unit
          title="查看报告"
          :validIconSRC="previewValidSRC"
          :inValidIconSRC="previewInvalidSRC"
          :isValid="canPreview"
          @onClick="onPreviewClick"
        ></report-cell-unit>
      </div>
    </div>
  </div>
</template>

<script>
import CellUnit from "./cell-unit.vue";
import ReportCellUnit from "../report/sleep-report/cell-unit.vue";
import { DateTool } from "@js/date-tool.js";
import dayjs from "dayjs";

export default {
  components: {
    CellUnit,
    ReportCellUnit,
  },

  props: {
    model: Object,
    isSelected: Boolean,
    canPreview: Boolean,
  },

  data: function () {
    return {
      trashSRC: require("@imgs/trash.png"),
      editSRC: require("@imgs/edit.png"),
      detailSRC: require("@imgs/detail.png"),
      previewValidSRC: require("@imgs/report-preview.png"),
      previewInvalidSRC: require("@imgs/report-preview-gray.png"),
    };
  },

  computed: {
    genderText: function () {
      return findKey(GENDER_CODE, `${this.model.sex}`);
    },

    monitorStatus() {
      return placeholderText(this.model.statusDesc);
    },

    ageText: function () {
      const birthDay = dayjs(this.model.birthday);
      if (!birthDay.isValid()) {
        return "-";
      }
      return DateTool.calculateAge(
        birthDay.year(),
        birthDay.month() + 1,
        birthDay.date()
      );
    },
  },

  methods: {
    onSelectClick: function () {
      this.$emit("onSelectClick", this.model);
    },

    onDeleteClick: function () {
      this.onSelectClick();
      this.$emit("onDeleteClick");
    },

    onEditClick: function () {
      this.onSelectClick();
      this.$emit("onEditClick");
    },

    onDetailClick: function () {
      this.onSelectClick();
      this.$emit("onDetailClick");
    },

    onPreviewClick: function () {
      this.onSelectClick();
      if (this.canPreview) {
        this.$emit("onPreviewClick");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  color: #666666;
  background-color: white;
  font-size: rem(14);
  line-height: rem(22);
  text-align: center;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  height: rem(54);
  display: flex;

  &:hover {
    background-color: #f7f7f7;
  }

  &-unit {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
      display: flex;
      align-items: center;

      &-slash {
        background-color: #e9e9e9;
        width: rem(1);
        height: rem(14);
        margin-left: rem(10);
        margin-right: rem(10);
      }
    }
  }

  .name {
    width: 8%;
  }

  .gender {
    width: 5%;
  }

  .age {
    width: 5%;
  }

  .phone-number {
    width: 13%;
  }

  .monitor-status {
    width: 18%;
  }

  .organization {
    width: 20%;
  }

  .operation {
    width: 31%;
  }
}

.selected {
  background-color: #e6f7ff;

  &:hover {
    background-color: #e6f7ff;
  }
}
</style>
