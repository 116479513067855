<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="user-dialog">
      <div class="user-dialog-top">
        <p class="user-dialog-top-title">
          {{ isEdit ? "编辑用户" : "新增用户" }}
        </p>
        <button
          class="user-dialog-top-close"
          :style="{
            backgroundImage: `url(${require('@imgs/close-card.png')})`,
          }"
          @click="onCloseClick"
        ></button>
      </div>
      <div class="user-dialog-middle">
        <!-- 基本字段 (fieldType=1) -->
        <template>
          <!-- 姓名 -->
          <div class="user-dialog-middle-name-container">
            <div class="user-dialog-middle-name-container-name">
              <must-label isMust text="姓名："></must-label>
              <border-input
                class="user-dialog-middle-name-container-name-input"
                @onInput="onNameInput"
                :initalValue="name"
              ></border-input>
            </div>
            <!-- 性别 -->
            <div class="user-dialog-middle-name-container-gender">
              <must-label isMust text="性别："></must-label>
              <switch-picker
                left="男"
                right="女"
                :initialIsPickRight="isFemale"
                @onPick="onGenderPick"
              ></switch-picker>
            </div>
          </div>

          <!-- 生日 -->
          <div class="user-dialog-middle-first-container">
            <div class="user-dialog-middle-first-container-birthday">
              <must-label isMust text="生日："></must-label>
              <el-select
                filterable
                v-model="year"
                placeholder="年"
                :popper-append-to-body="false"
                @visible-change="yearChange"
                ref="yearSelect"
                class="user-dialog-middle-first-container-birthday-year"
              >
                <el-option
                  v-for="item in yearList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                filterable
                v-model="month"
                placeholder="月"
                :popper-append-to-body="false"
                @visible-change="monthChange"
                ref="monthSelect"
                class="user-dialog-middle-first-container-birthday-month"
              >
                <el-option
                  v-for="item in monthList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                filterable
                v-model="day"
                placeholder="日"
                :popper-append-to-body="false"
                @visible-change="dayChange"
                ref="daySelect"
                class="user-dialog-middle-first-container-birthday-day"
              >
                <el-option
                  v-for="item in dayList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- 手机号 -->
          <div class="user-dialog-middle-phone">
            <must-label :isMust="!isEdit" text="手机号："></must-label>
            <border-input
              class="user-dialog-middle-phone-input"
              @onInput="onPhoneInput"
              :initalValue="phone"
              :disabled="isEdit"
            ></border-input>
          </div>
        </template>

        <!-- 扩展字段 (fieldType=2) -->
        <template v-for="(field, index) in typeTwoFields">
          <!-- 病案号 -->
          <div
            v-if="field.fieldCode === 'medical_record' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-number"
          >
            <must-label
              :isMust="getFieldRequiredStatus(field.fieldCode)"
              :text="field.fieldName + '：'"
            ></must-label>
            <border-input
              class="user-dialog-middle-number-input"
              @onInput="onNumberInput"
              :initalValue="number"
            ></border-input>
          </div>

          <!-- 身高 -->
          <div
            v-if="field.fieldCode === 'height' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-second-container"
          >
            <div class="user-dialog-middle-second-container-height">
              <must-label
                :isMust="getFieldRequiredStatus(field.fieldCode)"
                text="身高："
              ></must-label>
              <border-input
                class="user-dialog-middle-second-container-height-input"
                @onInput="onHeightInput"
                :initalValue="height"
              >
                <p class="user-dialog-middle-second-container-height-unit">
                  cm
                </p>
              </border-input>
            </div>
          </div>

          <!-- 体重 -->
          <div
            v-if="field.fieldCode === 'weight' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-second-container"
          >
            <div class="user-dialog-middle-second-container-weight">
              <must-label
                :isMust="getFieldRequiredStatus(field.fieldCode)"
                text="体重："
              ></must-label>
              <border-input
                class="user-dialog-middle-second-container-weight-input"
                @onInput="onWeightInput"
                :initalValue="weight"
              >
                <p class="user-dialog-middle-second-container-height-unit">
                  kg
                </p>
              </border-input>
            </div>
          </div>

          <!-- 身份证 -->
          <div
            v-if="field.fieldCode === 'id_card' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-ID"
          >
            <must-label
              :isMust="getFieldRequiredStatus(field.fieldCode)"
              :text="field.fieldName + '：'"
            ></must-label>
            <border-input
              class="user-dialog-middle-ID-input"
              @onInput="onIDInput"
              :initalValue="ID"
            ></border-input>
          </div>

          <!-- 地址 -->
          <div
            v-if="field.fieldCode === 'address' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-address"
          >
            <must-label
              :isMust="getFieldRequiredStatus(field.fieldCode)"
              :text="field.fieldName + '：'"
            ></must-label>
            <border-input
              class="user-dialog-middle-address-input"
              @onInput="onAddressInput"
              :initalValue="address"
            ></border-input>
          </div>

          <!-- 床位号 -->
          <div
            v-if="field.fieldCode === 'bed_number' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-row"
          >
            <must-label
              :isMust="getFieldRequiredStatus(field.fieldCode)"
              :text="field.fieldName + '：'"
            ></must-label>
            <border-input
              class="user-dialog-middle-row-input"
              @onInput="onBchInput"
              :initalValue="bch"
            ></border-input>
          </div>

          <!-- 科室 -->
          <div
            v-if="field.fieldCode === 'department' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-address"
          >
            <must-label
              :isMust="getFieldRequiredStatus(field.fieldCode)"
              :text="field.fieldName + '：'"
            ></must-label>
            <border-input
              class="user-dialog-middle-address-input"
              @onInput="onDepartmentInput"
              :initalValue="department"
            ></border-input>
          </div>

          <div
            v-if="field.fieldCode === 'photos' && field.fieldShow === 1"
            :key="field.id"
          >
            <!-- 正面照 -->
            <div class="user-dialog-middle-photo">
              <UploadPhoto
                type="front"
                :isMust="getFieldRequiredStatus(field.fieldCode)"
                :photoUrl.sync="frontPhoto"
                @onClickDelete="onClickDelete(0)"
                @onClickAdd="onClickAdd(0)"
              />
            </div>

            <!-- 左侧照 -->
            <div class="user-dialog-middle-photo">
              <UploadPhoto
                type="left"
                :isMust="getFieldRequiredStatus(field.fieldCode)"
                :photoUrl.sync="leftPhoto"
                @onClickDelete="onClickDelete(1)"
                @onClickAdd="onClickAdd(1)"
              />
            </div>

            <!-- 右侧照 -->
            <div class="user-dialog-middle-photo">
              <UploadPhoto
                type="right"
                :isMust="getFieldRequiredStatus(field.fieldCode)"
                :photoUrl.sync="rightPhoto"
                @onClickDelete="onClickDelete(2)"
                @onClickAdd="onClickAdd(2)"
              />
            </div>
          </div>

          <!-- 备注 -->
          <div
            v-if="field.fieldCode === 'remarks' && field.fieldShow === 1"
            :key="field.id"
            class="user-dialog-middle-note"
          >
            <must-label
              :isMust="getFieldRequiredStatus('remarks')"
              :text="field.fieldName + '：'"
            ></must-label>
            <border-input
              class="user-dialog-middle-note-input"
              @onInput="onNoteInput"
              :initalValue="remarks"
              placeholder="请输入备注信息"
            ></border-input>
          </div>
        </template>
      </div>

      <div class="user-dialog-bottom">
        <button
          v-if="!isEdit"
          class="user-dialog-bottom-scan"
          @click.prevent.stop="onQrCodeClick"
        >
          扫码填写
        </button>
        <button
          class="user-dialog-bottom-cancel"
          @click.prevent.stop="onCancelClick"
        >
          取消
        </button>
        <button
          class="user-dialog-bottom-confirm"
          @click.prevent.stop="onConfirmClick"
        >
          确定
        </button>
      </div>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        accept="image/*"
        @change="onFileChange"
      />

      <el-dialog
        :visible.sync="dialogVisible"
        title="检测到剪切板内容"
        class="user-dialog-dialog"
        append-to-body
      >
        <paste-dialog
          :content="clipboardContent"
          @onDialogConfirm="onDialogConfirm"
          @onDialogCancel="onDialogCancel"
        />
      </el-dialog>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view.vue";
import BorderInput from "@c/common/border-input.vue";
import SwitchPicker from "@c/device-manage/monitor/device-add/switch-picker.vue";
import MustLabel from "./user-add/must-label.vue";
import { DateTool } from "@js/date-tool.js";
import { Dialog } from "element-ui";
import pasteDialog from "./user-add/paste-dialog.vue";
import { parseClipboard } from "@js/parse-tool.js";
import { ValidateTool } from "@js/validate-tool.js";
import UploadPhoto from "./upload-photo.vue";

export default {
  name: "UserDialog",
  components: {
    PopView,
    BorderInput,
    SwitchPicker,
    MustLabel,
    elDialog: Dialog,
    pasteDialog,
    UploadPhoto,
  },

  props: {
    // 编辑模式时传入的用户模型
    model: {
      type: Object,
      default: () => ({}),
    },
    // 是否为编辑模式
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      number: "",
      name: "",
      phone: "",
      isFemale: false,
      birthday: new Date("2002-01-01"),
      height: "",
      weight: "",
      ID: "",
      address: "",
      bch: "",
      department: "",
      yearList: [],
      year: "",
      monthList: [],
      month: "",
      dayList: [],
      day: "",
      dialogVisible: false,
      clipboardContent: "",
      // 字段配置
      fieldList: [],
      // 按类型分组的字段列表
      typeOneFields: [], // fieldType=1，基本字段
      typeTwoFields: [], // fieldType=2，扩展字段
      loading: false,
      // 添加照片和备注字段
      frontPhoto: "",
      leftPhoto: "",
      rightPhoto: "",
      remarks: "",
      currentPhotoIndex: undefined,
    };
  },

  computed: {
    hospitalID: function () {
      if (this.isEdit) {
        return this.model.hospitalId;
      } else {
        return this.$store.state.selectedHospital.hospitalid;
      }
    },
  },

  mounted() {
    this.loading = true;
    this.getFieldConfig()
      .then(() => {
        this.initializeData();
        if (!this.isEdit) {
          this.year = "2000";
          this.month = "1月";
          this.day = "1日";
          this.calculateYear();
          this.calculateMonth();
          this.calculateDay(this.month);
        }

        // 仅在新增模式下检查剪贴板
        if (!this.isEdit) {
          this.getPaste();
        }
        this.loading = false;
      })
      .catch((err) => {
        console.error("加载字段配置失败", err);
        this.loading = false;
      });
  },

  methods: {
    // 获取字段配置
    async getFieldConfig() {
      try {
        const res = await this.$api.getUserFieldConfigList({
          hospitalId: this.hospitalID,
          platformType: 1, // 平台类型：睡眠管理平台
        });
        if (res && Array.isArray(res)) {
          const processedFields = res;
          // 按照fieldType分组字段
          this.typeOneFields = [
            processedFields.find((field) => field.fieldCode === "name"),
            processedFields.find((field) => field.fieldCode === "sex"),
            processedFields.find((field) => field.fieldCode === "birthday"),
            processedFields.find((field) => field.fieldCode === "phone"),
          ];

          this.typeTwoFields = processedFields
            .filter((field) => field.fieldType === 2)
            .sort((a, b) => a.sortOrder - b.sortOrder);
          // 将所有字段合并到fieldList，但保持分组顺序
          this.fieldList = [...this.typeOneFields, ...this.typeTwoFields];
        }
      } catch (err) {
        console.error("获取字段配置失败", err);
        this.$toast.showRed("获取字段配置失败");
      }
    },

    // 获取字段显示状态
    getFieldShowStatus(fieldCode) {
      const field = this.fieldList.find((item) => item.fieldCode === fieldCode);
      return field ? field.fieldShow === 1 : false;
    },

    // 获取字段必填状态
    getFieldRequiredStatus(fieldCode) {
      const field = this.fieldList.find((item) => item.fieldCode === fieldCode);
      return field ? field.fieldIsRequired === 1 : false;
    },

    async initializeData() {
      if (this.isEdit && this.model) {
        await this.loadUserData();
      }
    },

    async loadUserData() {
      try {
        this.loading = true;
        const params = { id: this.model.id };
        const response = await this.$api.getUserDetail(params);

        // 根据字段配置映射数据
        this.fieldList.forEach((field) => {
          switch (field.fieldCode) {
            case "medical_record":
              this.number = response.mzh || "";
              break;
            case "name":
              this.name = response.trueName || "";
              break;
            case "phone":
              this.phone = response.mobile || "";
              break;
            case "sex":
              this.isFemale = `${response.sex}` == GENDER_CODE.女;
              break;
            case "birthday":
              if (response.birthday == "") {
                let currentDate = new Date();
                let year = currentDate.getFullYear() - response.age;
                let realBirthday = (year + "-01" + "-01" + " 00:00:00").replace(
                  /-/g,
                  "/"
                );
                this.birthday = new Date(realBirthday);
              } else {
                this.birthday = new Date(
                  (response.birthday + " 00:00:00").replace(/-/g, "/")
                );
              }
              // 设置年月日选择器
              const birthYear = this.birthday.getFullYear();
              const birthMonth = this.birthday.getMonth() + 1;
              const birthDay = this.birthday.getDate();
              const ageText = DateTool.calculateAge(
                birthYear,
                birthMonth,
                birthDay
              );
              this.year = `${birthYear}  ${ageText}`;
              this.month = `${this.birthday.getMonth() + 1}月`;
              this.calculateYear();
              this.calculateMonth();
              this.calculateDay(this.month);
              this.day = `${this.birthday.getDate()}日`;
              break;
            case "height":
              this.height = response.height || "";
              break;
            case "weight":
              this.weight = response.weight || "";
              break;
            case "id_card":
              this.ID = response.idCard || "";
              break;
            case "address":
              this.address = response.address || "";
              break;
            case "bed_number":
              this.bch = response.bch || "";
              break;
            case "department":
              this.department = response.department || "";
              break;
            case "photos":
              this.frontPhoto = response.frontPhoto || "";
              this.leftPhoto = response.leftPhoto || "";
              this.rightPhoto = response.rightPhoto || "";
              break;
            case "remarks":
              this.remarks = response.remarks || "";
              break;
          }
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toast.showRed(error);
      }
    },

    onDialogConfirm() {
      this.dialogVisible = false;
      const form = parseClipboard(this.clipboardContent);
      this.number = form.number || "";
      this.name = form.name || "";

      let age = Number(form.age);
      let birthday = form.birthday;
      if (birthday) {
        this.birthday = birthday;
        this.year = `${birthday.getFullYear()}  ${age}岁`;

        this.$nextTick().then(() => {
          this.day = birthday.getDate() + "日";
          this.month = birthday.getMonth() + 1 + "月";
        });
      }

      if (form.sex && form.sex.includes("男")) {
        this.isFemale = false;
      } else if (form.sex) {
        this.isFemale = true;
      }

      this.height = form.height || "";
      this.weight = form.weight || "";
      this.phone = form.mobile || "";
      this.bch = form.bch || "";
      this.department = form.department || "";
      this.remarks = form.remarks || "";
    },

    onDialogCancel() {
      this.dialogVisible = false;
    },

    async getPaste() {
      var content = await navigator.clipboard?.readText();
      if (content != "" && content != undefined && content != null) {
        if (content.includes("姓名") && content.includes("性别")) {
          this.dialogVisible = true;
          this.clipboardContent = content;
        }
      }
    },

    yearChange(item) {
      if (item) {
        this.$refs.yearSelect.focus();
      } else {
        this.$refs.yearSelect.blur();
      }
    },

    monthChange(item) {
      if (item) {
        this.$refs.monthSelect.focus();
      } else {
        this.$refs.monthSelect.blur();
      }
    },

    dayChange(item) {
      if (item) {
        this.$refs.daySelect.focus();
      } else {
        this.$refs.daySelect.blur();
      }
    },

    calculateDay: function (month) {
      if (
        month == "1月" ||
        month == "3月" ||
        month == "5月" ||
        month == "7月" ||
        month == "8月" ||
        month == "10月" ||
        month == "12月"
      ) {
        this.cycleDay(31);
      } else {
        if (month == "2月") {
          if (this.year.slice(0, 4) % 4 === 0) {
            this.cycleDay(29);
          } else {
            this.cycleDay(28);
          }
        } else {
          this.cycleDay(30);
        }
      }
    },

    cycleDay: function (value) {
      this.dayList = [];
      this.day = "1日";
      for (let index = 1; index <= value; index++) {
        this.dayList.push({ value: `${index}日` });
      }
    },

    calculateYear: function () {
      let date = new Date();
      let year = date.getFullYear();
      this.yearList = [];

      if (this.year == "") {
        // 默认使用2000年
        const birthYear = 2000;
        const birthMonth = parseInt(this.month.replace("月", ""));
        const birthDay = parseInt(this.day.replace("日", ""));

        const ageText = DateTool.calculateAge(birthYear, birthMonth, birthDay);
        this.year = `${birthYear}  ${ageText}`;

        // 生成年份列表
        for (let index = 1900; index <= year; index++) {
          const ageText = DateTool.calculateAge(index, birthMonth, birthDay);
          this.yearList.push({ value: `${index}  ${ageText}` });
        }
      } else {
        // 已有年份，从中提取
        const birthYear = parseInt(this.year.slice(0, 4));
        const birthMonth = parseInt(this.month.replace("月", ""));
        const birthDay = parseInt(this.day.replace("日", ""));

        const ageText = DateTool.calculateAge(birthYear, birthMonth, birthDay);
        this.year = `${birthYear}  ${ageText}`;

        // 生成年份列表
        for (let index = 1900; index <= year; index++) {
          const ageText = DateTool.calculateAge(index, birthMonth, birthDay);
          this.yearList.push({ value: `${index}  ${ageText}` });
        }
      }
    },

    calculateMonth() {
      this.monthList = [];
      for (let index = 1; index <= 12; index++) {
        this.monthList.push({ value: `${index}月` });
      }
    },

    onCloseClick: function () {
      this.$emit("onCloseClick");
    },

    onNumberInput: function (value) {
      this.number = value;
    },

    onNameInput: function (value) {
      this.name = value;
    },

    onPhoneInput: function (value) {
      this.phone = value;
    },

    onGenderPick: function (isPickRight) {
      this.isFemale = isPickRight;
    },

    onHeightInput: function (value) {
      this.height = value;
    },

    onWeightInput: function (value) {
      this.weight = value;
    },

    onIDInput: function (value) {
      this.ID = value;
    },

    onAddressInput: function (value) {
      this.address = value;
    },

    onBchInput: function (value) {
      this.bch = value;
    },

    onDepartmentInput: function (value) {
      this.department = value;
    },

    onCancelClick: function () {
      this.onCloseClick();
    },

    onConfirmClick: function () {
      // 验证必填字段
      const requiredFields = this.fieldList.filter(
        (field) =>
          (field.fieldIsRequired === 1 && field.fieldShow === 1) ||
          field.fieldType === 1
      );

      for (const field of requiredFields) {
        // 使用字段的fieldCode来判断
        switch (field.fieldCode) {
          case "medical_record":
            if (this.number === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "name":
            if (this.name === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "phone":
            if (this.phone === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "height":
            if (this.height === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "weight":
            if (this.weight === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "id_card":
            if (this.ID === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "address":
            if (this.address === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "bed_number":
            if (this.bch === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "department":
            if (this.department === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "photos":
            if (this.frontPhoto === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            if (this.leftPhoto === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            if (this.rightPhoto === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
          case "remarks":
            if (this.remarks === "") {
              this.$toast.showRed(MSG.necessaryEmpty);
              return;
            }
            break;
        }
      }

      // 手机号验证
      if (
        this.phone !== "" &&
        !ValidateTool.isPhoneNumber(this.phone) &&
        !this.isEdit
      ) {
        this.$toast.showRed(MSG.phoneNumberError);
        return;
      }

      this.birthday = new Date(
        (
          `${this.year.slice(0, 4)}-${this.month.replace(
            "月",
            ""
          )}-${this.day.replace("日", "")}` + " 00:00:00"
        ).replace(/-/g, "/")
      );

      if (this.birthday > new Date()) {
        this.$toast.showRed("生日不能晚于今天");
        return;
      }

      if (this.height !== "" && !ValidateTool.isAvailableHeight(this.height)) {
        this.$toast.showRed(MSG.heightNotPosstiveNumber);
        return;
      }

      if (this.weight !== "" && !ValidateTool.isAvailableWeight(this.weight)) {
        this.$toast.showRed(MSG.weightNotPosstiveNumber);
        return;
      }
      if (this.ID !== "" && !ValidateTool.isAvailableID(this.ID)) {
        this.$toast.showRed(MSG.idAvailableNumber);
        return;
      }

      if (this.remarks !== "" && this.remarks.length > 500) {
        this.$toast.showRed(MSG.remarksLengthError);
        return;
      }

      const userInfo = {
        number: this.number,
        name: this.name,
        phone: this.phone,
        isFemale: this.isFemale,
        birthday: DateTool.formatDate(this.birthday, "Y-M-D"),
        height: this.height,
        weight: this.weight,
        ID: this.ID,
        address: this.address,
        bch: this.bch,
        department: this.department,
        frontPhoto: this.frontPhoto,
        leftPhoto: this.leftPhoto,
        rightPhoto: this.rightPhoto,
        remarks: this.remarks,
      };

      // 编辑模式需额外传递用户ID
      if (this.isEdit && this.model) {
        userInfo.uid = this.model.uid;
        userInfo.hospitalID = this.model.hospitalid;
        userInfo.id = this.model.id;
      }

      this.$emit("onConfirmClick", userInfo);
    },

    onQrCodeClick() {
      this.$emit("onQrCodeClick");
    },

    onFrontPhotoInput(value) {
      this.frontPhoto = value;
    },

    onLeftPhotoInput(value) {
      this.leftPhoto = value;
    },

    onRightPhotoInput(value) {
      this.rightPhoto = value;
    },

    onNoteInput(value) {
      this.remarks = value;
    },
    // 照片
    onClickDelete(index) {
      switch (index) {
        case 0:
          this.frontPhoto = "";
          break;
        case 1:
          this.leftPhoto = "";
          break;
        case 2:
          this.rightPhoto = "";
          break;
      }
    },
    onClickAdd(index) {
      this.currentPhotoIndex = index;
      //选择图片上传
      this.$refs.fileInput.click();
    },

    async onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          const base64 = await this.readFileAsDataURL(file);
          await this.$api.faceRecognition({
            imageBase64: base64.split(",")[1],
          });
          const fileData = new FormData();
          fileData.set("file", file);
          fileData.set("fileName", file.name);
          const data = await this.$api.uplodFileWithNameRequest(fileData);
          if (data) {
            if (this.currentPhotoIndex === 0) {
              this.frontPhoto = data;
            } else if (this.currentPhotoIndex === 1) {
              this.leftPhoto = data;
            } else if (this.currentPhotoIndex === 2) {
              this.rightPhoto = data;
            }
          }
        } catch (error) {
          this.$toast.showRed(error);
        }
      }
    },

    readFileAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
  },

  watch: {
    month(newValue, oldValue) {
      if (oldValue != "") {
        this.calculateYear();
        this.calculateDay(newValue);
      }
    },
    year(newValue, oldValue) {
      if (oldValue != "") {
        if (newValue.slice(0, 4) != oldValue.slice(0, 4)) {
          this.month = "1月";
          this.calculateDay(this.month);
        }
      }
    },
    day(newValue) {
      this.calculateYear();
    },
    hospitalID() {
      this.getFieldConfig();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dialog {
  width: rem(483);

  &-top {
    width: 100%;
    height: rem(55);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    padding: rem(0) rem(24);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(16);
    }

    &-close {
      width: rem(16);
      height: rem(16);
      background-size: rem(16) rem(16);
      background-repeat: no-repeat;
    }
  }

  &-middle {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    line-height: rem(20);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    width: 100%;
    height: rem(560);
    padding-bottom: rem(20);
    overflow-y: auto; /* 添加滚动条以适应更多字段 */

    &-number {
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(65);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-name-container {
      display: flex;

      &-name {
        height: rem(32);
        margin-top: rem(29);
        margin-left: rem(78);
        display: flex;
        align-items: center;

        &-input {
          width: rem(108);
          height: 100%;
        }
      }

      &-gender {
        margin-left: rem(10);
        margin-top: rem(29);
        height: 100%;
        display: flex;
        align-items: center;

        &-input {
          width: rem(119);
          height: 100%;
        }
      }
    }

    &-phone {
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(65);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-first-container {
      display: flex;
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(78);

      &-birthday {
        height: 100%;
        display: flex;
        align-items: center;

        &-year {
          width: rem(125);
          height: rem(32);
        }

        &-month {
          width: rem(76);
          height: rem(32);
          margin-left: rem(4);
        }

        &-day {
          width: rem(76);
          height: rem(32);
          margin-left: rem(4);
        }

        /deep/ .el-input__inner {
          height: rem(32);
        }

        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }

    &-second-container {
      display: flex;
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(78);

      &-height {
        height: 100%;
        display: flex;
        align-items: center;

        &-input {
          width: rem(286);
          height: 100%;
        }

        &-unit {
          color: #909399;
          font-size: 14px;
          margin-top: -2px;
        }
      }

      &-weight {
        height: 100%;
        display: flex;
        align-items: center;

        &-input {
          width: rem(286);
          height: 100%;
        }
      }
    }

    &-ID {
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(65);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-address {
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(78);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-row {
      height: rem(32);
      display: flex;
      align-items: center;
      margin-top: rem(23);
      margin-left: rem(65);

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-photo {
      height: 193px;
      margin-top: rem(10);
      // margin-left: rem(64);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-note {
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(78);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }
  }

  &-bottom {
    width: 100%;
    height: rem(56);
    box-sizing: border-box;
    padding: rem(10) rem(16) 0;
    display: flex;
    justify-content: flex-end;

    &-confirm {
      color: white;
      background-color: #325fac;
      font-size: rem(14);
      border-radius: rem(3);
      width: rem(65);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cancel {
      color: rgba($color: #000000, $alpha: 0.65);
      background-color: white;
      font-size: rem(14);
      border-radius: rem(3);
      border: rem(1) solid #e8e8e8;
      width: rem(65);
      height: rem(32);
      margin-right: rem(8);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-scan {
      color: #325fac;
      font-size: 14px;
      border-radius: 4px;
      width: 89px;
      height: 32px;
      border: 1px solid #325fac;
      margin-right: auto;
    }
  }

  &-dialog {
    display: flex;
    align-items: center;
    width: auto;

    ::v-deep .el-dialog__body {
      display: flex;
      justify-content: center;
      padding: 0px;
      background-color: transparent;
    }

    ::v-deep .el-dialog__header {
      padding: 0px;
      display: none;
    }

    ::v-deep .el-dialog {
      box-shadow: none;
      background: transparent;
    }
  }
}
</style>
